import React, {useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function Game_detail(props){
    //i18n
    const { t, i18n } = useTranslation('home');

    
    const gamemode = props.gamemode;
    const colorThemes = [{gamemode: 'TDM', color: 't-blue'},
                        {gamemode: 'LMS', color: 't-redorange'},
                        {gamemode: 'MEGA', color: 't-purple'},
                        {gamemode: '6VS6', color: 't-green'},
                        ];
    const colorTheme = colorThemes.find(item => item.gamemode === gamemode)?.color;

    return(
        <div className='game_detail'>
            <div className='container rounded bluebox t-out-shadow m-auto p-4'>
                <div className='title mx-auto d-flex justify-content-center'>
                    <h1 className=''>{t(`gamemodes.${gamemode}.title1`)} <span className={colorTheme}>{t(`gamemodes.${gamemode}.title2`)}</span></h1>
                </div>
                <Container className='context tcontext mt-3 t-out-shadow'>
                    <Row className='mx-auto '>
                        <Col xs={12} lg={6} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label1`)} </span>{t(`gamemodes.${gamemode}.info1`)}
                        </Col>
                        <Col xs={12} lg={6} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label2`)} </span>{t(`gamemodes.${gamemode}.info2`)}
                        </Col>
                    </Row>
                    <Row className='mx-auto '>
                        <Col xs={12} lg={6} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label3`)} </span>{t(`gamemodes.${gamemode}.info3`)}
                        </Col>
                        <Col xs={12} lg={6} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label4`)} </span>{t(`gamemodes.${gamemode}.info4`)}
                        </Col>
                    </Row>
                    <Row className='mx-auto'>
                        <Col xs={12} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label5`)} </span>{t(`gamemodes.${gamemode}.info5`)}
                        </Col>
                        <Col xs={12} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label6`)} </span>{t(`gamemodes.${gamemode}.info6`)}
                        </Col>
                        <Col xs={12} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label7`)} </span>{t(`gamemodes.${gamemode}.info7`)}
                        </Col>
                        <Col xs={12} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label8`)} </span>{t(`gamemodes.${gamemode}.info8`)}
                        </Col>
                        <Col xs={12} className='my-2'>
                            <span className={colorTheme}>{t(`gamemodes.${gamemode}.label9`)} </span>{t(`gamemodes.${gamemode}.info9`)}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='clickclose tcontexth2 t-border-black ' onClick={props.closefunction}><p></p>click to close</div>
        </div>
    )
}