import React, {useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import "react-image-gallery/styles/css/image-gallery.css"; // Import the default styles


import "./home.css"
import "./home4.css"

export default function Home4(){
    //i18n
    const { t, i18n } = useTranslation('home');
    const [ charPose, setCharPose ] = useState(0);
    const [ charSelected, setCharSelected] = useState(0);

    const charModels = [
        {
            key: 'eric',
            index: 0,
            name: 'ERIC',
            thumbnailxl: 'images/home/Profile_01_C.jpg',
            thumbnail: 'images/home/Profile_01_C.jpg',
            poses: [
                '/images/home/Business_tr_0.png',
                '/images/home/Business_tr_1.png',
                '/images/home/Business_tr_2.png',
            ],
            rank: 'images/home/rank_c.png',
            statcount: 3,
        },
        {
            key: 'jessie',
            index: 1,
            name: 'JESSIE',
            thumbnailxl: 'images/home/Profile_02_C.jpg',
            thumbnail: 'images/home/Profile_02_C.jpg',
            poses: [
                '/images/home/Pretty_tr_0.png',
                '/images/home/Pretty_tr_1.png',
                '/images/home/Pretty_tr_2.png',
            ],
            rank: 'images/home/rank_c.png',
            statcount: 2,
        },
        {
            key: 'raya',
            index: 2,
            name: 'RAYA',
            thumbnailxl: 'images/home/Profile_03_C.jpg',
            thumbnail: 'images/home/Profile_03_C.jpg',
            poses: [
                '/images/home/Cool_tr_0.png',
                '/images/home/Cool_tr_1.png',
                '/images/home/Cool_tr_2.png',
            ],
            rank: 'images/home/rank_c.png',
            statcount: 4,
        },
        {
            key: 'feaow',
            index: 3,
            name: 'FEAOW',
            thumbnailxl: 'images/home/Profile_04_C.jpg',
            thumbnail: 'images/home/Profile_04_C.jpg',
            poses: [
                '/images/home/Biker_tr_0.png',
                '/images/home/Biker_tr_1.png',
                '/images/home/Biker_tr_2.png',
            ],
            rank: 'images/home/rank_c.png',
            statcount: 3,
        },
    ]

    const onPoseClick = (e, value) => {
        setCharPose(Number.parseInt(value) || 0);
    }

    const onCharClick = (e, value) => {
        setCharSelected(Number.parseInt(value) || 0);
    }

    const CharView = (char) => {
        return(
            <div className='charview mx-auto px-5'>
                <img className='char-pose img-fluid' src={charModels[charSelected].poses[charPose]} />
                <div className='poses d-flex justify-content-center mx-auto'>
                    <div className='btn-pose pose0 m-1' onClick={(e) => onPoseClick(e, 0)}>
                        <img className={'img-fluid ' + ((charPose==0)? 'clicked':'')} src='/images/home/char_pose00.png' />
                    </div>
                    <div className='btn-pose pose1 m-1' onClick={(e) => onPoseClick(e, 1)}>
                        <img className={'img-fluid ' + ((charPose==1)? 'clicked':'')} src='/images/home/char_pose01.png' />
                    </div>
                    <div className='btn-pose pose2 m-1' onClick={(e) => onPoseClick(e, 2)}>
                        <img className={'img-fluid ' + ((charPose==2)? 'clicked':'')} src='/images/home/char_pose02.png' />
                    </div>
                </div>
            </div>
        )
    }

    const CharInfoSM = () => {
        return(
            <div className='info-context d-flex flex-column mx-auto p-2'>
                <div className='title d-flex justify-content-center'>
                    <h1 className='my-auto'>{t(`charaters.${charModels[charSelected].key}.name`)}</h1>
                    <img className='rank ps-2 m-1' src={charModels[charSelected].rank} />
                </div>
                <div className='context mt-2'>
                    {t(`charaters.${charModels[charSelected].key}.story`)}
                </div>
                <Container className='status-context mt-auto'>
                    <div className='title2'>
                        <h1>STATUS</h1>
                    </div>
                    <Row className='status d-flex flex-row justify-content-between'>
                        <Col xs sm={6} className='stat d-flex flex-row flex-nowrap'><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat1`)}</Col>
                        <Col xs sm={5} className='stat d-flex flex-row'><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat2`)}</Col>
                    </Row>
                    <Row className='status d-flex flex-row justify-content-between mt-2'>
                        <Col xs sm={6} className='stat d-flex flex-row flex-nowrap'><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat3`)}</Col>
                        <Col xs sm={5} className='stat d-flex flex-row'><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat4`)}</Col>
                    </Row>
                </Container>
            </div>
        )
    }

    const CharInfoXXL = () => {
        return(
            <div className='info-context d-flex flex-column my-auto py-4 px-5'>
                <div className='title d-flex justify-content-center'>
                    <h1 className='my-auto'>{t(`charaters.${charModels[charSelected].key}.name`)}</h1>
                    <img className='rank ps-2 m-1' src={charModels[charSelected].rank} />
                </div>
                <div className='context mt-2'>
                {t(`charaters.${charModels[charSelected].key}.story`)}
                </div>
                <br/>
                <div className='status-context mt-auto'>
                    <div className='title2'>
                        <h1>STATUS</h1>
                    </div>
                    <div className='status d-flex flex-row justify-content-between'>
                        <div className='stat d-flex flex-row flex-nowrap '><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat1`)}</div>
                        {(charModels[charSelected].statcount>=2) && <div className='stat d-flex flex-row'><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat2`)}</div>}
                        {(charModels[charSelected].statcount>=3) && <div className='stat d-flex flex-row flex-nowrap'><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat3`)}</div>}
                        {(charModels[charSelected].statcount>=4) && <div className='stat d-flex flex-row'><div className='ytag me-2'></div>{t(`charaters.${charModels[charSelected].key}.stat4`)}</div>}
                    </div>
                </div>
            </div>
        )
    }

    const CharSelectXXL = () => {
        return(
            <div className='charsel-xxl m-auto mt-5'>
                <div className='info-context-wrapper d-flex flex-row justify-content-center'>
                    <img className='img-fluid charinfobg' src='images/home/char_info_bg.png' />
                    <img className='img-fluid props logo' src='images/logo_140.png' />
                    <img className='img-fluid props drone' src='images/home/mmenu_prop03.png' />
                    <div className='charinfo-wrapper'>
                        <CharInfoXXL />
                    </div>
                </div>
                <div className='characters d-flex flex-row justify-content-between p-0'>
                    <div className='character d-flex flex-column me-auto' onClick={(e) => onCharClick(e, 0)}>
                        <img className={'img-fluid ' + (charSelected==0?'selected':'')} src={charModels[0].thumbnailxl} />
                        <h1 className='ms-3 t-out-shadow'>{t(`charaters.${charModels[0].key}.name`)}</h1>
                        <img className='char-rank' src={charModels[0].rank} />
                    </div>
                    <div className='character d-flex flex-column mx-auto' onClick={(e) => onCharClick(e, 1)}>
                        <img className={'img-fluid ' + (charSelected==1?'selected':'')} src={charModels[1].thumbnailxl} />
                        <h1 className='ms-3 t-out-shadow'>{t(`charaters.${charModels[1].key}.name`)}</h1>
                        <img className='char-rank' src={charModels[0].rank} />
                    </div>
                    <div className='character d-flex flex-column mx-auto' onClick={(e) => onCharClick(e, 2)}>
                        <img className={'img-fluid ' + (charSelected==2?'selected':'')} src={charModels[2].thumbnailxl} />
                        <h1 className='ms-3 t-out-shadow'>{t(`charaters.${charModels[2].key}.name`)}</h1>
                        <img className='char-rank' src={charModels[0].rank} />
                    </div>
                    <div className='character d-flex flex-column ms-auto' onClick={(e) => onCharClick(e, 3)}>
                        <img className={'img-fluid ' + (charSelected==3?'selected':'')} src={charModels[3].thumbnailxl} />
                        <h1 className='ms-3 t-out-shadow'>{t(`charaters.${charModels[3].key}.name`)}</h1>
                        <img className='char-rank' src={charModels[0].rank} />
                    </div>
                    
                </div>
                
            </div>
        )
    }

    const myRenderRightNav = (onClick, disabled) => {
        return (
            <img src= {'./images/home/arrow_right.png'}
                className= { 'image-gallery-right-nav'}
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    const myRenderLeftNav = (onClick, disabled) => {
        return (
            <img src= {'./images/home/arrow_left.png'}
                className= { 'image-gallery-left-nav'}
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    const thumbnailInner = (item) => {
        return(
            <div className='item d-flex flex-column '>
                <img className={'img-fluid ms-2 ' + (charSelected==item.index?'selected':'')} src={item.thumbnail} />
                <h1 className='ms-3 t-out-shadow'>{t(`charaters.${charModels[item.index].key}.name`)}</h1>
                <img className='char-rank' src={item.rank} />
                
            </div>
        )
    }

    const myRenderItem = (item) => {
        return (
            <div></div>
            // <CharView />
        )
    }

    const handleOnSlide = (index) => {
        setCharSelected(index);
    }

    return(
        <div id='home4' className='home4 d-flex flex-column'>
            <div className='bg'>
                <img className='img-fluid' src='images/home/home4_bg.jpg' />
            </div>
            <div className='front d-none d-xl-block'>
                <div className='frontxxl d-flex flex-row justify-content-center m-auto maxw-1920'>
                    <CharView />
                    <CharSelectXXL />
                </div>
            </div>
            <div className='front d-flex flex-column d-block d-xl-none mx-auto'>
                <div>
                    <CharView />
                </div>
                
                <p/>
                <ImageGallery
                    items={charModels}
                    showIndex={false}
                    showThumbnails={true}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showNav={true}
                    infinite={false}
                    thumbnailPosition={'top'}
                    renderRightNav = { myRenderRightNav }
                    renderLeftNav = { myRenderLeftNav }
                    renderThumbInner = {thumbnailInner}
                    renderItem = {myRenderItem}
                    onSlide={handleOnSlide}
                />
                
            </div>
            <div className='info-bg d-block d-xl-none py-3'>
                <CharInfoSM />
            </div>
        </div>
    )
}