import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';

import { useEffect, useRef, Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { Route, Routes, Link, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Home from './home/home.js';
import Policies from './policies/policies';
import Ticket from './ticket/ticket.js';
import EVSample from './events/sample';

import Events from './events/events.js';
import EVChapter1 from './events/chapter1/chapter1.js';

function App() {
  const location = useLocation();
  const theme = createTheme({
    palette: {
      primary: {
        main: '#07B0EE',  //blue
      },
      secondary: {
        main: '#747474',  //light gray
      },
    },
  });
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* TODO fallback to loading page */}
        <Suspense fallback="..loading">
          <Routes>
            {/* TODO <Route path='*' element={<Header />} /> */}
          </Routes>
            
          <Routes>
            <Route exact path='/home' element={<Home />} />
            <Route exact path='/policies' element={<Policies />} />
            <Route exact path='/ticket' element={<Ticket />} />
            <Route exact path='/events/sample' element={<EVSample />} />
            <Route exact path='/events/chapter1' element={<EVChapter1 />} />

            <Route path='/events' element={ <Events /> }/>
            <Route path='/events/chapter1' element={ <EVChapter1 /> }/>
            <Route path='/' element={ <Navigate to="/home" /> }/>
            <Route path='/*' element={ <Navigate to="/home" /> }/>
          </Routes>

          <Routes>
            {/* <Route path='/controlpanel/*'></Route> */}
            {/* <Route path="*" element={<Footer />} /> */}
          </Routes>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}

export default App;
