import React, {useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material'; 
import Modal from '@mui/material/Modal';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';

import Game_detail from './game_detail';
import Home3 from './home3';
import Home4 from './home4';
import Home5 from './home5';
import Footer from './footer';

import "./home.css"

export default function Home(){
    //i18n
    const { t, i18n } = useTranslation('home');

    const [isToggle, setIsToggle] = useState(false);
    const [isattop, setIsAtTop] = useState(true)
    const [showGameModeDetail, setShowGameModeDetail] = useState(false);
    const [gameMode, setGameMode] = useState('');

    const doToggle = () => {
        setIsToggle(!isToggle);
    }

    //navbar scroll changing function
    const checkIsAtTop = () => {
        if (window.scrollY >= 32) {
            setIsAtTop(false);
        }else{
            setIsAtTop(true);
        }
    }

    const closeGameModeDetail = () => {
        setShowGameModeDetail(0);
    }



    useEffect(() => {
        checkIsAtTop()
        // adding the event when scroll change background
        window.addEventListener("scroll", checkIsAtTop)
    })

    return(
        <div className='home'>
            {/* collapse burger menu for < xl */}
            <div className={'layer11 p-0 m-0 d-block d-lg-none' + (isToggle? ' toggled': '')} >
                {isToggle && <div className='props'>
                    <img className='prop01' src='./images/home/mmenu_prop01.png'/>
                    <img className='prop02' src='./images/home/mmenu_prop02.png'/>
                    <img className='prop03' src='./images/home/mmenu_prop03.png'/>
                    <img className='prop04' src='./images/home/mmenu_prop04.png'/>
                </div>}
                <Navbar collapseOnSelect='true' expand="lg" bg="transparent" variant="none" sticky="none" expanded={isToggle}
                    className={'mt-5'} >
                    <Navbar.Toggle aria-controls="basic-navbar-nav" 
                        className='navtoggle px-2 py-0 me-1' onClick={()=> doToggle()} >
                            <img className='hamburger img-fluid m-auto' 
                                src={isToggle? './images/home/burger_toggled.png' : './images/home/burger.png'}></img>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className='w-100 mt-0 '>
                        
                            {/* <div className='full-screen-menu w-100 d-block d-xl-none'> */}
                                {/* logo and center objects */}
                                {/* <div className='d-flex'>    */}
                                    <div className='logo mx-auto'>
                                        <a href='/home'><img className='img-fluid mx-auto' src='images/logo_500.png' /></a>
                                    </div>
                                {/* </div> */}
                                {/* <div className='layer13 d-flex justify-content-end'>
                                    <div className='navtoggle p-0 mt-2 me-1' onClick={()=> doToggle()}>
                                        <img className='hamburger img-fluid m-auto' 
                                            src='./images/home/burger_toggle.png'></img>
                                    </div>
                                </div>  */}
                            {/* </div> */}
                            

                            <a href='/home' onClick={()=> doToggle()}><h1 className='item t-border-red t-shadow25 my-4'>OVERVIEW</h1></a>
                            <a href='/home#home4' onClick={()=> doToggle()}><h1 className='item t-border-red t-shadow25 my-4'>CHARACTER</h1></a>
                            <a href='/ticket' onClick={()=> doToggle()}><h1 className='item t-border-red t-shadow25 my-4'>SUPPORT</h1></a>
                            <a href='/policies?doc=privacy'><h1 className='item t-border-red t-shadow25 my-4'>POLICY</h1></a>
                        
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className='home_header d-flex w-100'>
                <img className='g-logo img-fluid' src='./images/commons/logo_gmillion.png' />
            </div>
            <div className='home1'>
                {/* background + zombies layer */}
                <div className='bg'>
                    <div className=''>
                        <img className='layer0 img-fluid img-bg' src='/images/home/home1_bg.jpg' />
                        <img className={'layer0 darker img-fluid img-bg transi-ease-in-out ' + (isattop? '':'show') } src='/images/home/home1_bg.jpg' />
                        <div className={'tint  mt-auto transi-ease-in-out '  + (isattop? '':'show')}></div>
                    </div>    
                    <div className='d-flex d-none d-md-block'>
                        <div className='layer1 d-flex flex-column align-items-end'>
                                <img className='img-bg img-fluid mx-auto mt-auto opacity-ease-in-out' 
                                    src={'/images/home/zombies_layer1_light.png'} />
                                <img className={'img-bg darker img-fluid mx-auto mt-auto opacity-ease-in-out ' + (isattop? '':'show') }
                                    src={'/images/home/zombies_layer1_dark.png'} />
                        </div>
                    </div>
                    <div className='w-100' style={{position:'absolute', bottom: 0}}>
                        {/* <img class='layer2 img-fluid img-bg mx-auto mb-0 pb-0 ' 
                            src={isattop? '/images/home/zombies_layer2_light.png':'/images/home/zombies_layer2_dark.png'} /> */}
                        <div className='layer_wrapper mx-auto'>
                            <img class='layer2 img-fluid img-bg mx-auto mb-0 pb-0 opacity-ease-in-out ' 
                                src={'/images/home/zombies_layer2_light.png'} />
                            <img class={`layer2 darker img-fluid img-bg mx-auto mb-0 pb-0 opacity-ease-in-out ` + (isattop? '':'show')}
                                src={'/images/home/zombies_layer2_dark.png'} />
                        </div>
                    </div>
                    
                </div>
                <div className='layer10 w-100 d-flex justify-content-center'>   {/* menu layer */}
                    <div className='layer13 maxw-1920 d-none d-lg-block mx-auto'> {/* text menu for xl+ */}
                        <div className='header d-flex justify-content-between'>
                            <div className='menu-box pt-3'>
                                <div className='d-flex justify-content-end'>
                                    <div className='menu'>
                                        <a href='/home'><h1 className='t-border-red t-shadow25'>OVERVIEW</h1></a>
                                        
                                    </div>
                                    <div className='menu'>
                                        <a href='/home#home4'><h1 className='t-border-red t-shadow25'>CHARACTER</h1></a>
                                    </div>
                                </div>
                                <div className='line t-shadow25'></div>
                            </div>
                            
                            <div className='menu logo pt-3'></div>
                            
                            <div className='menu-box pt-3'>
                                <div className='d-flex justify-content-start'>
                                    <div className='menu'>
                                        <a href='/ticket'><h1 className='t-border-red t-shadow25'>SUPPORT</h1></a>
                                    </div>
                                    <div className='menu'>
                                        <a href='/policies?doc=privacy'><h1 className='t-border-red t-shadow25'>POLICY</h1></a>
                                    </div>
                                </div>
                                <div className='line t-shadow25'></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {/* logo and center objects */}
                <div className='layer12'>   
                    <div className='menu logo pt-4 mx-auto'>
                        <a href='/home'><img className='img-fluid mx-auto' src='images/logo_500.png' /></a>
                        <div className='t-border-black'><h1>PvPvE, THE DANGEROUS GAME</h1></div>
                        <div className='store d-flex flex-row justify-content-center mx-auto'>
                            <div className='aos mx-2'>
                                <a href='/coming_soon'><img className='img-fluid' src='images/store_aos.png' /></a>
                            </div>
                            <div className='ios mx-2'>
                                <a href='/coming_soon'><img className='img-fluid' src='images/store_ios.png' /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyright tcontext t-border-black-sm w-100'> {/* copyright */}
                    {isattop && <div>@2023 G MILLION GAMES. ALL RIGHTS RESERVED.</div>}
                </div>
            </div>


            <Container fluid className='home2 my-5 d-flex justify-content-center'>
                <Row className='gamemodes'>
                    <Col xs={6} lg={3} className='game-mode my-2 col-6'>
                        <img className='img-fluid rounded' src='images/home/gamemode_4TEAM.jpg' />
                        <div className='btn-info' onClick={() => {setGameMode('TDM');setShowGameModeDetail(1);}}>
                            <img className='img-fluid' src='/images/home/btn_info_01.png'/>
                        </div>
                    </Col>
                    <Col xs={6} lg={3} className='game-mode my-2 col-6'>
                        <img className='img-fluid rounded m-0 p-0' src='images/home/gamemode_LSM.jpg' />
                        <div className='btn-info' onClick={() => {setGameMode('LMS');setShowGameModeDetail(1);}}>
                            <img className='img-fluid' src='/images/home/btn_info_01.png'/>
                        </div>
                    </Col>
                    <Col xs={6} lg={3} className='game-mode my-2 col-6'>
                        <img className='img-fluid rounded' src='images/home/gamemode_MEGA.jpg' />
                        <div className='btn-info' onClick={() => {setGameMode('MEGA');setShowGameModeDetail(1);}}>
                            <img className='img-fluid' src='/images/home/btn_info_01.png'/>
                        </div>
                    </Col>
                    <Col xs={6} lg={3} className='game-mode my-2 col-6'>
                        <img className='img-fluid rounded' src='images/home/gamemode_6VS6.jpg' />
                        <div className='btn-info' onClick={() => {setGameMode('6VS6');setShowGameModeDetail(1);}}>
                            <img className='img-fluid' src='/images/home/btn_info_01.png'/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal keepMounted open={showGameModeDetail} onClose={() => setShowGameModeDetail(0)}
                className='home-modal'
            >
                <Game_detail gamemode={gameMode} closefunction={closeGameModeDetail} />
            </Modal>

            <Home3 />
            <Home4 />
            <Home5 />
            <Footer />
        </div>
    )
}
