import React, {useRef, useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material'; 
import Modal from '@mui/material/Modal';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import axios from "axios";

import "./chapter1.scss"
import { Button } from 'bootstrap/dist/js/bootstrap.bundle.min';
import QuestProgress from './quest_progress';
import SliderBillboard from './slider-billboard';
import RightPanel from './right-panel';
import Modal1 from '../../utils/components/modal1';

export default function EVChapter1(){
    const initialized = useRef(false);
    const location = useLocation();
    const rightPanelRef = useRef();
    const [showModalQuest, setShowModalQuest] = useState(false);
    const [page, setPage] = useState(0);
    const st_zombie_normal = 0;
    const st_zombie_targeted = 1;
    const st_zombie_shooted = 2;
    const st_zombie_unshoot = 3;
    const zombie_classname = [ , 'targeted', 'shooted', 'unshoot'];

    const [targeted, setTargeted] = useState(null);
    const [shooted, setShooted] = useState(false);

    const [targetInfo, setTargetInfo] = useState({
        target_stage: [st_zombie_normal, st_zombie_normal, st_zombie_normal],
        img_zombie: ['/images/events/chapter1/zombie1.png'
            ,'/images/events/chapter1/zombie2.png'
            ,'/images/events/chapter1/zombie3.png'
        ],
        img_reward: ['/images/events/chapter1/reward-zombie1.png'
            ,'/images/events/chapter1/reward-zombie2.png'
            ,'/images/events/chapter1/reward-zombie3.png'
        ],
        text_reward: [
            'SCAR-H SMILE POWER',
            'M4A4 FLOWER PLUS',
            'M4A1-S FLOWER PLUS'
        ]
    })

    const language = new URLSearchParams(location.search).get("lang");

    //store all player data and quests progress of that player
    const [playerData, setPlayerData] = useState({
        authen_code: new URLSearchParams(location.search).get("code"),
        token: null,
        uuid: null,
        nickname: null,
        bullet_free: 0,
        bullet_cash: 0,
        quest_progress: 0,
        quest_reward: [false, false, false, false, false, false, false, false],
    })

    //request token from code
    const getPlayerInfo = () => {
        //TODO axios call api for player token from code

        //TODO > replace player's token from reply
        setPlayerData((prev) => ({
            ...prev,
           token: "eyK0QsdfdvoiderSRGsgswl21rwgvnSDFSF",
           uuid: "AAAA-BBBB-CCCC-DDDD",
           nickname: "readyPlayer1",
           bullet_free: 17,
           bullet_cash: 0,
           quest_progress: 24,
           quest_reward: [true, true, false, false, false, false, false, false],
        }));

        //return response if success
        // return true;
    }


    const target = (item, index) => {
        return(
            <div className={`target m-1`} 
                onClick={() => ((!shooted) && on_select_target(index))}
            >
                {
                    (item.target_stage[index] == st_zombie_shooted) && 
                    (<div className='reward'>
                        <img className='img-fluid' src={item.img_reward[index]} />
                        <div className='reward-text highlight'>{item.text_reward[index]}</div>
                    </div>)
                }{
                    (item.target_stage[index] == st_zombie_unshoot) && 
                    (<div className='reward'>
                        <img className='img-fluid' src={item.img_reward[index]} />
                        <div className='reward-text'>{item.text_reward[index]}</div>
                    </div>)
                }
                <img 
                    className={'zombie ' + zombie_classname[item.target_stage[index]]}
                    src={item.img_zombie[index]} />

                {/* {(item.target_stage[index] == st_zombie_targeted) && <img className='crosshair' 
                    src='/images/events/chapter1/crosshair.png' />} */}
            </div>
        )
    }

    const on_shoot = () => {
        //set zombie stage to shooted
        var shoot_result = [st_zombie_unshoot, st_zombie_unshoot, st_zombie_unshoot];
        shoot_result[targeted] = st_zombie_shooted;
        setShooted(true);

        setTargetInfo((prev) => ({
            ...prev,
            target_stage: shoot_result,
        }));

        //TODO update bullet count
        setPlayerData((prev) => ({
            ...prev,
           bullet_free: prev.bullet_free -1,
        }));
    }

    const on_select_target = (index) => {
        var targeting = [st_zombie_normal, st_zombie_normal, st_zombie_normal];
        targeting[index] = st_zombie_targeted;

        // check if selected is the same with the selecting
        if(targeted == index){
            on_shoot();
            rightPanelRef.current.onShootProcess();
        }else{
            setTargeted(index);
            setTargetInfo((prev) => ({
                ...prev,
                target_stage: targeting,
            }));
        }
    }


    const Page1 = () => {
        return(
            <div className='layer1 d-flex flex-column p-0 m-0 justify-content-between'>
                <div className='layer1-1 d-flex ms-4 mt-5'>
                    <SliderBillboard className='sliderbillboard' />
                </div>
                <div className='layer1-2 d-flex ps-4 py-2'>
                    <QuestProgress />
                </div>
            </div>
        )
    }

    const Page2 = () => {
        return(
            <div className='layer1 d-flex flex-column p-0 m-0'>
                <div className='page2 d-flex flex-row justify-content-between m-auto'>
                        <div className={'context-inner mx-auto ' }>
                            <div className={'header-area mt-1 mx-auto d-flex flex-row showed' }>
                                {!shooted && <p className='d-flex mx-auto'>CHOOSE YOUR TARGET</p>}
                                {shooted && <p className='d-flex mx-auto'>YOU RECEIVE</p>}
                            </div>
                            <div className='target-area d-flex flex-row m-3 justify-content-between'>
                                {target(targetInfo, 0)}
                                {target(targetInfo, 1)}
                                {target(targetInfo, 2)}
                                
                            </div>
                        </div>
                </div>
            </div>
        )
        
    }

    const onStartClick = () => {
        setTargeted(null);
        setShooted(false);
        setPage(1);
    }

    const onShootClick = () => {
        on_shoot();
    }

    const onContinueClick = () => {
        setPage(0);
        setTargetInfo((prev) => ({
            ...prev,
            target_stage: [st_zombie_normal, st_zombie_normal, st_zombie_normal],
        }));
    }

    const ModalQuest = () => {
        return(
            <Modal1
                className='modal-quest'
                open={showModalQuest} 
                onClose={() => setShowModalQuest(false)}
                header={() =>{
                    return(
                        <div className='quest-header'>
                            <div className='header1'>DAILY QUEST</div>
                            <div className='header2'>00:00:000 20/07/24 - 23:59:59 21/07/24</div>
                            
                        </div>
                    )
                }}
                context={() =>{
                    return(
                        <div className='quest-context mt-1 p-2 d-flex flex-column '>
                            <div>test</div>
                            <div className='done'>test2</div>
                        </div>
                    )
                }}

            />
        )
    }

    //Run once to .... on page load
    useEffect(() => {
        if (initialized.current) {
            return;
        }

        initialized.current = true
        
        // Run the function once on component load
        getPlayerInfo();
    }, []);
    

    return(
        <div className='evchap1'>
            <div className='bg'>
                <img className='fluid img-bg' src='/images/events/chapter1/bg-rain-city1.jpg' />
            </div>
            <div className='context w-100 d-flex flex-row '>
                { (page == 0) && <Page1 /> }
                { (page == 1) && <Page2 /> }
                <div className='layer2 p-0 m-0 d-flex'>
                    <RightPanel 
                        ref={rightPanelRef}
                        onStart={onStartClick}
                        onShoot={onShootClick}
                        onContinue={onContinueClick}
                        onQuest={() => setShowModalQuest(true)}
                        targeted={targeted}
                        freeBullet={playerData.bullet_free}
                    />
                </div>
                {
                    (page == 0) &&
                    <div className='layer-logo d-flex flex-column mx-auto'>
                        <div className='logo-img d-flex flex-column'>
                            <img className='img-fluid m-auto ms-1' src='/images/events/chapter1/logo1.png' />
                            <img className='img-fluid m-auto ms-1' src='/images/events/chapter1/logo2.png' />
                        </div>
                        <div className='logo-text'>11:00:00 20/07/24 - 23:59:59 04/08/24 !</div>
                        <div className='authcode'>code: {playerData.authen_code}</div>
                    </div>
                }
            </div>
            <ModalQuest />
        </div>
    )
}
 