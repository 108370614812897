import { useLocation, Navigate } from 'react-router-dom';

//redirect to correct event page
export default function Events(){
    const location = useLocation();

  // Preserve the query string from the current location
  const searchParams = location.search;

  //TODO check system date and redirect to correct event page
  return <Navigate to={`/events/chapter1${searchParams}`} replace />;
}